import { useState } from 'react';
import { Form, redirect, useActionData, useNavigation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FrommError, frommClient } from '~/src/fromm';

import { Input, Button } from '@nextui-org/react';

export async function action({ request }: { request: Request }) {
  let formData = await request.formData();
  let email = formData.get('email') as string;
  let password = formData.get('password') as string;
  try {
    await frommClient.signIn(email, password, uuidv4(), false);
    return redirect('/friends');
  } catch (error) {
    if (error instanceof FrommError) {
      return error;
    }
    throw error;
  }
}

export default function Login() {
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let error = useActionData() as FrommError | undefined;
  let { state } = useNavigation();
  let hasPreviousUserInfo = localStorage.getItem('userInfo') !== null;
  let isFormDisabled = !hasPreviousUserInfo || state !== 'idle';
  return (
    <main className='min-h-screen flex items-center justify-center'>
      <Form method='post' className='max-w-md w-full m-4 flex flex-col gap-4'>
        <h2 className='text-center text-3xl font-extrabold'>
          Sign in to Your Account
        </h2>
        <Input
          name='email'
          type='email'
          required
          label='Email'
          classNames={{ input: 'text-base' }}
          value={email}
          onChange={e => setEmail(e.target.value)}
          isDisabled={isFormDisabled}
        />
        <Input
          name='password'
          type='password'
          required
          label='Password'
          classNames={{ input: 'text-base' }}
          value={password}
          onChange={e => setPassword(e.target.value)}
          disabled={isFormDisabled}
        />
        {error && (
          <div className='text-danger-500 text-sm'>{error.message}</div>
        )}
        {error && error.message === '잘못된 인증입니다.' && (
          <div className='text-danger-500 text-sm'>
            Fromm 측에서 로그인을 막은 것으로 추정됩니다. 2~5분 내로 복구될
            예정이니 조금만 기다려주세요.
          </div>
        )}

        <Button
          type='submit'
          color='primary'
          className='font-medium'
          isDisabled={isFormDisabled}
          isLoading={state !== 'idle'}
        >
          {hasPreviousUserInfo
            ? 'Sign in'
            : 'Fromm 측 요청으로 서비스 이용이 불가합니다'}
        </Button>
      </Form>
    </main>
  );
}
