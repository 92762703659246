import { redirect } from 'react-router-dom';

export async function loader() {
  localStorage.setItem('userInfo', '');
  return redirect('/');
}

export default function Unlock() {
  return null;
}
