import 'inter-ui/inter.css';

import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Modal from 'react-modal';
import * as Sentry from '@sentry/react';

import {
  FirebaseAppProvider,
  FirestoreProvider,
  AuthProvider,
} from 'reactfire';
import { NextUIProvider } from '@nextui-org/react';

Sentry.init({
  dsn: 'https://869df1d6b45ab436dfcc0395307f02f1@o4505666811920384.ingest.sentry.io/4505666813231104',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'fromm.goranmoomin.dev',
        'fromm-cors.goranmoomin.workers.dev',
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import { frommClient } from './fromm';

import Landing from './routes/landing';
import Friends, {
  loader as friendsLoader,
  ChatPlaceholder,
} from './routes/friends';
import Chat, { loader as chatLoader } from './routes/chat';
import Login, { action as loginAction } from './routes/login';
import Unlock, { loader as unlockLoader } from './routes/unlock';

let router = createBrowserRouter([
  {
    path: '',
    element: <Landing />,
  },
  {
    path: 'friends',
    element: <Friends />,
    loader: friendsLoader,
    children: [
      {
        path: '',
        element: <ChatPlaceholder />,
      },
      {
        path: ':friendId',
        element: <Chat />,
        loader: chatLoader,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
    action: loginAction,
  },
  {
    path: 'unlock',
    element: <Unlock />,
    loader: unlockLoader,
  },
]);

Modal.setAppElement('#root');
Modal.defaultStyles = {};

createRoot(document.getElementById('root')!).render(
  <NextUIProvider>
    <FirebaseAppProvider firebaseApp={frommClient.app} suspense>
      <AuthProvider sdk={frommClient.auth}>
        <FirestoreProvider sdk={frommClient.firestore}>
          <RouterProvider router={router} />
        </FirestoreProvider>
      </AuthProvider>
    </FirebaseAppProvider>
  </NextUIProvider>,
);
